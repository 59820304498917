import { API_URLS, getApiUrl } from '../Utils/apiURLConfiguration';
import GWRApiClient from './GWRApiClient';

/**
 * Concrete implementation for the Manage Booking endpoints
 *
 * @class ManageBookingApiClient
 * @extends {GWRApiClient}
 */
export default class ManageBookingApiClient extends GWRApiClient {
  constructor(version = '', token = '', tiogasAPI = false) {
    let apiVersion = version !== '' ? version : `v1`;
    const urlAux = getApiUrl(API_URLS.REACT_APP_MANAGE_BOOKING_ENDPOINT, apiVersion, tiogasAPI);
    super(urlAux, token);
  }

  /**
   * function returns the preview cancellation information
   *
   * @function
   * @param { reservationId: string}
   */
  async previewCancel(reservationId) {
    const urlRequest = `${this.url}/reservation/${reservationId}/cancellation/preview`;
    return await super.getApiCall(urlRequest);
  }

  /**
   * function returns the add guest modal information
   *
   * @function
   * @param { reservationId: string, requestObj: {kidsAges: [number]}}
   */
  async getAddGuestsModalInformation(reservationId, requestObj) {
    const adultsString = requestObj?.adults ? 'numberOfAdults=' + requestObj.adults : '';
    let kidsAgesString = '';
    requestObj?.kidsAges &&
      requestObj.kidsAges.forEach(kid => {
        kidsAgesString += '&kidsAges=' + kid;
      });
    if (adultsString === '' && kidsAgesString !== '') kidsAgesString = kidsAgesString.substring(1);
    const hasQueryData = adultsString !== '' || kidsAgesString !== '' ? '?' : '';
    const urlRequest =
      `${this.url}/reservation/${reservationId}/guests/add/preview` + hasQueryData + adultsString + kidsAgesString;
    return await super.getApiCall(urlRequest);
  }

  /*
   * function returns the information for the reservation
   *
   * @function
   * @param { reservationId: string }
   */
  async manageBooking(reservationId) {
    return await super.getApiCall(`${this.url}/reservations/${reservationId}`);
  }

  /*
   * function returns the add guest modal information after saving the new guests
   *
   * @function
   * @param { reservationId: string, requestObj: {numberOfAdults: number, kidsAges: [number]}}
   */
  async updateAddGuestsModal(reservationId, requestObj) {
    const urlRequest = `${this.url}/reservation/${reservationId}/guests/add`;
    return await super.postApiCall(urlRequest, requestObj);
  }

  /**
   * Function to cancel a reservation given a reservationId
   *
   * @param {string} reservationId
   * @param { reason: string, comment: string } requestObj
   * @returns
   */
  async cancelReservation(reservationId, requestObj) {
    const urlRequest = `${this.url}/reservation/${reservationId}/cancellation`;
    return await super.postApiCall(urlRequest, requestObj);
  }
}
