import { creditCardType } from 'card-validator';
import get from 'lodash-es/get';
import PaymentApiClient from '../../api/clients/PaymentApiClient';
import RoomBookingApiClient from '../../api/clients/RoomBookingApiClient';
import Shift4i4GoApiClient from '../../api/clients/Shift4i4GoApiClient';
import { isLocationAvailableWithAffirm } from '../../api/Utils/affirmUtils';
import { isPayPalEnabled } from '../../api/Utils/paypalUtils';
import { editCopyByReplaceString } from '../../components/utilities/copyFunctions';
import { store } from '../../store/configureStore';
import { getNormalizedCode } from '../../utilities/availabilityUtils';
import {
  ASSETS_LOCATIONS,
  DATE_FORMATS,
  LATE_CHECKOUT_PREFIXES,
  SITE_NIAGON,
  SUPPORTED_COUNTRIES,
  getAssetsUrl
} from '../../utilities/constants';
import { isDevEnvironment } from '../../utilities/env';
import { maskPhone } from '../../utilities/maskUtils';
import { checkObjectProperties } from '../../utilities/objectKeysManagementUtils';
import { GwDatesWrapper } from '../_internal_date_/gwDatesWrapper';
import unmaskValue from '../utilities/unmaskValue';
import createReservationSchema from './createReservationSchema';

import { PAYMENT_FORM_IMAGES } from './assets/images';
import * as COPY from './utilities/copy';

const DAY_PASS_PAGE_TYPE = 'daypass';
/**
 * Type of payment methods
 * @enum {string}
 */
export const paymentMethods = {
  CREDIT_CARD: 1,
  AFFIRM: 3,
  PAYPAL: 4,
  SHIFT4: 5,
  APPLE_PAY: 5,
  GOOGLE_PAY: 5
};

const paymentFormLogos = {
  creditCard: getAssetsUrl(ASSETS_LOCATIONS.ROOT, PAYMENT_FORM_IMAGES.UI_ICON_CREDITCARD),
  affirm: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, PAYMENT_FORM_IMAGES.AFFIRM_LOGO),
  paypal: 'https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_100x26.png',
  applePay: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, PAYMENT_FORM_IMAGES.APPLE_PAY_LOGO),
  googlePay: getAssetsUrl(ASSETS_LOCATIONS.CONTENT, PAYMENT_FORM_IMAGES.GOOGLE_PAY_LOGO),
  shift4: 'https://www.shift4.com/wp-content/uploads/2019/05/shift4-logo.png'
};

/**
 * Payment methods configuration objects to be used to return the payment type
 * on the paload.
 * @enum {Object}
 */
const paymentMethododsConf = {
  'credit-card': {
    type: paymentMethods.CREDIT_CARD,
    label: 'creditCard'
  },
  affirm: {
    type: paymentMethods.AFFIRM,
    label: 'affirm'
  },
  applePayEnabled: {
    type: paymentMethods.APPLE_PAY,
    label: 'digitalWallet'
  },
  googlePayEnabled: {
    type: paymentMethods.GOOGLE_PAY,
    label: 'digitalWallet'
  },
  paypal: {
    type: paymentMethods.PAYPAL,
    label: 'paypal'
  },
  shift4: {
    type: paymentMethods.SHIFT4,
    label: 'ALTERNATIVE'
  }
};

const paymentMethodsTypes = {
  CREDIT_CARD: 'credit-card',
  CREDIT_CARD_ENABLED: 'creditEnabled',
  AFFIRM: 'affirm',
  AFFIRM_ENABLED: 'affirmEnabled',
  PAYPAL: 'paypal',
  PAYPAL_ENABLED: 'payPalEnabled',
  APPLE_PAY: 'applePayEnabled',
  GOOGLE_PAY: 'googlePayEnabled'
};

/**
 * Type of credit cards
 * @enum {string}
 */
const creditCardTypes = {
  visa: 'VS',
  mastercard: 'MC',
  'american-express': 'AX',
  discover: 'DS',
  default: 'any'
};

const CREDIT_CARD_TEST_NUMBER = '4444333322221111';
const DEFAULT_OFFER_CODE = 'BBAR';
const SOURCE_CODE = 'WEBN';
const OCCASION = 'BDAY';
const NEXT_GEN_QA = 'NextGenQA';
const APPLE_PAY_TYPE = 'applePay';
const GOOGLE_PAY_TYPE = 'googlePay';
export const DEFAULT_RATE_CODE = 'AMTCERT';

/**
 * Formik initial values
 */
const getInitialValues = selectedPaymentMethod => ({
  contactInfo: {
    phone: '',
    firstName: '',
    lastName: '',
    email: '',
    textAgreement: false
  },
  billingInfo: {
    billingAddress: '',
    postalCode: '',
    city: '',
    state: 'AL',
    country: SUPPORTED_COUNTRIES.UnitedStates
  },
  paymentOptions: {
    selected: selectedPaymentMethod ?? 'credit-card',
    ccNameOnCard: '',
    ccMonth: '',
    ccYear: '',
    ccCvv: '',
    ccCardNumber: '',
    paypal: {
      nonce: '',
      firstName: '',
      lastName: '',
      email: '',
      deviceId: ''
    },
    googlePay: {
      tokenizationData: {},
      type: '',
      info: {},
      description: ''
    },
    applePay: {
      paymentData: {},
      paymentMethod: {},
      transactionIdentifier: ''
    }
  },
  specialRequests: [],
  specialRequestsOther: '',
  specialOccasion: [],
  specialOccasionOther: '',
  specialOccasionBirthday: '',
  isCASLMarked: false
});

/**
 * Based on a credit card type, return the correspondant code. On dev
 * environment pass the test code "1111222233334444" as a visa card.
 * @param {number} cardNumber Credit card number.
 * @return {string} Credit card code.
 */
export const getCardCode = cardNumber => {
  if (cardNumber === CREDIT_CARD_TEST_NUMBER) {
    return creditCardTypes.visa;
  }

  const cardType = creditCardType(cardNumber);
  const [bestCardMatch] = cardType;

  if (!bestCardMatch) return creditCardTypes.default;

  const { type: bestCardType } = bestCardMatch;
  return creditCardTypes[bestCardType] || creditCardTypes.default;
};

/**
 *
 * @param {*} formData
 * @param {*} stateData
 */
const getAffirmCheckoutPayload = (formData, stateData) => {
  const { contactInfo, billingInfo } = formData;
  const {
    selectedSuite,
    checkinDateSelection,
    checkoutDateSelection,
    resortLocation,
    selectedPackages,
    summaryTotal,
    summaryTaxes
  } = stateData;
  const displayName = `${COPY.GREAT_WOLF_LODGE} ${resortLocation} ; ${resortLocation}, ${COPY.US}; ${checkinDateSelection}-${checkoutDateSelection}; ${selectedSuite.title}`;

  const billingInformation = {
    name: {
      first: contactInfo.firstName,
      last: contactInfo.lastName
    },
    address: {
      line1: billingInfo.billingAddress,
      line2: billingInfo.addressLineTwo || '',
      city: billingInfo.city,
      state: billingInfo.state,
      zipcode: billingInfo.postalCode,
      country: billingInfo.country
    },
    email: contactInfo.email
  };

  const filteredSelectedPackages = selectedPackages.filter(
    filteredPackage => filteredPackage.isAdjustment === undefined
  );

  return {
    metadata: {
      mode: 'modal',
      entity_name: resortLocation
    },
    merchant: {
      user_confirmation_url: `${window.location.origin}/plan/payment`,
      user_cancel_url: `${window.location.origin}/plan/payment`,
      user_confirmation_url_action: 'GET',
      name: 'Great Wolf Lodge'
    },
    billing: billingInformation,
    shipping: {
      ...billingInformation,
      phone_number: unmaskValue(contactInfo.phone)
    },
    items: [
      {
        display_name: displayName,
        sku: selectedSuite.suiteCode,
        unit_price: selectedSuite?.totalRate * 100,
        qty: 1,
        // Item image and item url are rendering a relative path but I think
        // should render an absolute path to those urls for affirm
        item_image_url: selectedSuite.mainImage,
        item_url: selectedSuite.anchorPath
      },
      ...filteredSelectedPackages.map(selectedPackage => {
        return {
          display_name: selectedPackage.packageName || get(selectedPackage, 'shortDescription', ''),
          sku: selectedPackage.packageCode,
          unit_price: Math.abs(selectedPackage.total.toFixed(2) * 100),
          qty: selectedPackage.quantity,
          item_image_url: '',
          item_url: ''
        };
      })
    ],
    shipping_amount: 0,
    tax_amount: parseInt(Math.round(summaryTaxes * 100)),
    total: parseInt(Math.round(summaryTotal * 100))
  };
};

/**
 * Returns and transform all payment details based on the type of payment
 * selected.
 * @param {Object} paymentData Data coming from the payment form
 * @param {Object} stateData Redux state data
 */
const getPaymentDetails = (paymentData, stateData) => {
  const { ccYear, ccMonth, ccCardNumber, ccNameOnCard, ccCvv, selected } = paymentData;
  const {
    resortLocation,
    summaryTotal,
    checkoutToken,
    paypal,
    optimizelyChargePackagesAtCheckout,
    googlePay,
    applePay,
    profile,
    offer,
    suiteRates,
    showLoyalty
  } = stateData;
  const expiration = GwDatesWrapper.format(`${ccYear}-${ccMonth}-05`, DATE_FORMATS.default);
  let paymentObject = {};
  if (selected === 'credit-card') {
    paymentObject = {
      number: ccCardNumber,
      holder: ccNameOnCard,
      expiration,
      securityCode: 0,
      cardCode: getCardCode(ccCardNumber),
      cidNumber: ccCvv,
      ccYear: ccYear,
      ccMonth: ccMonth
    };
  } else if (selected === 'affirm') {
    paymentObject = {
      checkoutToken: checkoutToken,
      property: resortLocation,
      amount: summaryTotal,
      reservationId: null
    };
  } else if (selected === 'paypal') {
    paymentObject = {
      nonce: paypal.nonce,
      firstName: paypal.firstName,
      lastName: paypal.lastName,
      email: paypal.email,
      deviceId: paypal.deviceId
    };
  } else if (selected === 'googlePayEnabled') {
    paymentObject = {
      cardCode: googlePay.i4go_cardtype,
      extendedCardData: googlePay.i4go_extendedcarddata,
      holder: googlePay.i4go_cardholdername,
      expirationMonth: googlePay.i4go_expirationmonth,
      expirationYear: googlePay.i4go_expirationyear,
      token: googlePay.i4go_uniqueid,
      lastFourDigits: googlePay.otn.cardnumber.slice(12)
    };
  } else if (selected === 'applePayEnabled') {
    const billingContactData = JSON.parse(applePay.i4go_applepaytoken);
    paymentObject = {
      cardCode: applePay.i4go_cardtype,
      extendedCardData: applePay.i4go_extendedcarddata,
      holder:
        applePay?.i4go_cardholdername ??
        `${billingContactData.billingContact.givenName} ${billingContactData.billingContact.familyName}`,
      expirationMonth: applePay.i4go_expirationmonth,
      expirationYear: applePay.i4go_expirationyear,
      token: applePay.i4go_uniqueid,
      lastFourDigits: applePay.otn.cardnumber.slice(12)
    };
  }

  const handlePoints = () => {
    if (showLoyalty && profile && profile.user && profile.user.pointsBalance) {
      if (profile.user.pointsBalance <= suiteRates[0].totalNightlyRate) {
        return profile.user.pointsBalance.toFixed();
      } else if (profile.user.pointsBalance > suiteRates[0].totalNightlyRate) {
        return suiteRates[0].totalNightlyRate.toFixed();
      }
    } else {
      return 0;
    }
  };

  return {
    type: paymentMethododsConf[selected].type,
    [paymentMethododsConf[selected].label]: paymentObject,
    shouldChargePackagesUpfront: optimizelyChargePackagesAtCheckout,
    points: Number(handlePoints()) || 0,
    offers: offer && offer.activeOffers ? offer.activeOffers : []
  };
};

/**
 * Returns a formed object containning address details.
 * @param {Object} billingData Payment form billing data
 * @param {Object} contactData Payment form contact data
 */
const getAddressDetails = (billingData, contactData) => {
  const { billingAddress, addressLineTwo, city, state, country, postalCode } = billingData;
  const { email } = contactData;
  return {
    street1: billingAddress,
    street2: addressLineTwo || '',
    city: city,
    state: state,
    country: country,
    postalCode: postalCode,
    email: email
  };
};

/**
 * Returns a formed object containing all the order details.
 * @param {Object} formData Payment form data
 * @param {Object} stateData Redux state data
 */
const getOrderDetails = (formData, stateData) => {
  const {
    specialRequests,
    specialRequestsOther,
    specialOccasion,
    specialOccasionBirthday,
    specialOccasionOther
  } = formData;
  const {
    selectedSuite,
    checkinDateSelection,
    checkoutDateSelection,
    adultsCount,
    kidsAges,
    selectedPackages,
    dayPasses,
    pageType,
    validOfferCode,
    qualifyingID,
    resortLocation
  } = stateData;

  const getOfferCode = () => {
    if (pageType === DAY_PASS_PAGE_TYPE) return '';
    if (isDevEnvironment) return DEFAULT_RATE_CODE;

    if (selectedSuite?.offerType === DEFAULT_OFFER_CODE) {
      return DEFAULT_OFFER_CODE;
    }

    return validOfferCode ? getNormalizedCode(validOfferCode) : getNormalizedCode(selectedSuite?.rateCode);
  };

  const getRateCode = () => {
    if (pageType === DAY_PASS_PAGE_TYPE) return dayPasses.selectedRateCode;

    return getNormalizedCode(selectedSuite?.rateCode);
  };
  const commentsList = [specialRequestsOther];

  if (pageType === DAY_PASS_PAGE_TYPE && dayPasses.qualifyingId) {
    commentsList.push(editCopyByReplaceString(COPY.QUALIFYING_ID, dayPasses.qualifyingId, '<ID>'));
  } else if (qualifyingID) {
    commentsList.push(editCopyByReplaceString(COPY.QUALIFYING_ID, qualifyingID, '<ID>'));
  }

  return {
    comments: commentsList,
    specialRequests: specialRequests.map(request => ({
      requestId: 0,
      code: request
    })),
    // what is source code?
    sourceCode: SOURCE_CODE,
    selection: {
      property: resortLocation,
      arrival:
        pageType === DAY_PASS_PAGE_TYPE
          ? GwDatesWrapper.format(dayPasses.arrivalDate, DATE_FORMATS.default)
          : GwDatesWrapper.format(checkinDateSelection, DATE_FORMATS.default),
      departure:
        pageType === DAY_PASS_PAGE_TYPE
          ? GwDatesWrapper.add(dayPasses.arrivalDate, 1, 'days')
          : GwDatesWrapper.format(checkoutDateSelection, DATE_FORMATS.default),
      roomCode: pageType === DAY_PASS_PAGE_TYPE ? dayPasses.selectedItemCode : selectedSuite.suiteCode,
      suiteDescription: pageType === DAY_PASS_PAGE_TYPE ? dayPasses.item.description : selectedSuite.title,
      adults: pageType === DAY_PASS_PAGE_TYPE ? dayPasses.numberOfGuests : adultsCount,
      children: pageType === DAY_PASS_PAGE_TYPE ? [] : kidsAges,
      offerCode: pageType === DAY_PASS_PAGE_TYPE ? getNormalizedCode(dayPasses.promoCode) : getOfferCode(),
      rateCode: getRateCode(),
      site: resortLocation,
      availabilityKey: selectedSuite?.availabilityKey,
      suiteContent: {
        maxOccupancy: selectedSuite?.maxOccupancy,
        suiteCategory: selectedSuite?.suiteCategory
      }
    },
    alerts: specialOccasion.map(occasion => ({
      alert_code: occasion,
      alert_description:
        occasion === OCCASION
          ? editCopyByReplaceString(COPY.WISH_HAPPY_BIRTH_DAY, specialOccasionBirthday, '<BIRTH_DAY>')
          : specialOccasionOther
    })),
    packages:
      pageType === DAY_PASS_PAGE_TYPE
        ? selectedPackages.map(selectedPackage => {
            const { packageCode, isAdjustment, startDate, endDate, packageType, isDiningByPackage } = selectedPackage;
            return {
              packageCode,
              isAdjustment,
              quantity: 1,
              packageType: packageType && packageType.length > 0 ? packageType[0] : '',
              isDiningByPackage,
              startDate: GwDatesWrapper.format(startDate, DATE_FORMATS.default),
              endDate: GwDatesWrapper.format(endDate, DATE_FORMATS.default)
            };
          })
        : selectedPackages.map(selectedPackage => {
            const {
              packageCode,
              isAdjustment,
              quantity,
              startDate,
              endDate,
              packageType,
              isDiningByPackage,
              availabilityKey
            } = selectedPackage;

            return {
              availabilityKey,
              packageCode,
              isAdjustment,
              quantity,
              packageType: packageType && packageType.length > 0 ? packageType[0] : '',
              isDiningByPackage,
              startDate: GwDatesWrapper.format(startDate, DATE_FORMATS.default),
              endDate: GwDatesWrapper.format(endDate, DATE_FORMATS.default)
            };
          }),
    items:
      pageType === DAY_PASS_PAGE_TYPE
        ? // Create an "array" of items containing the day passes information
          [
            {
              Quantity: dayPasses.numberOfGuests,
              Date: GwDatesWrapper.format(dayPasses.arrivalDate, DATE_FORMATS.default),
              ItemCode: dayPasses.selectedItemCode,
              RateCode: getRateCode()
            }
          ]
        : // if the page type is a reservation, we should send the
          // late checkout packages as an item so the backend can discount
          // the item quantity
          selectedPackages
            .filter(selectedPackage => selectedPackage.packageCode.includes(LATE_CHECKOUT_PREFIXES.code))
            .map(selectedPackage => {
              const { quantity, total, packageCode } = selectedPackage;
              return {
                Quantity: quantity,
                Date: GwDatesWrapper.format(checkoutDateSelection, DATE_FORMATS.default), // should be applied for the stay checkout date
                Total: total,
                ItemCode: packageCode,
                RateCode: getRateCode()
              };
            })
  };
};

/**
 * Return customer details
 * @param {Object} contactInfo User data
 */
const getCustomerDetails = contactInfo => {
  return {
    // where to pull gender?
    gender: 1,
    firstName: contactInfo.firstName,
    lastName: isDevEnvironment ? NEXT_GEN_QA : contactInfo.lastName,
    phoneNumber: maskPhone(contactInfo.phone),
    canContactBySms: contactInfo.textAgreement,
    isCASLMarked: contactInfo.isCASLMarked,
    isPhoneUpdated: contactInfo?.isPhoneUpdated ?? false
  };
};

/**
 * Returns the payment info returned by Paypal checkout
 * @param {Object} paypal Paypal success object
 */
const getPaypalPaymentInfo = paypal => {
  return {
    nonce: paypal.nonce,
    firstName: paypal.details.firstName,
    lastName: paypal.details.lastName,
    email: paypal.details.email,
    deviceId: paypal.deviceId
  };
};

/**
 * Returns the billing info selected on Paypal site
 * @param {Object} paypal Paypal success object
 */
const getPaypalBillingInfo = paypal => {
  return {
    billingAddress: paypal.details.billingAddress.line1 || '',
    postalCode: paypal.details.billingAddress.postalCode || '',
    city: paypal.details.billingAddress.city || '',
    state: paypal.details.billingAddress.state || '',
    country: paypal.details.billingAddress.countryCode || ''
  };
};

/**
 * Returns the payment info returned by Google Pay checkout
 * @param gPay
 * @returns {{country: (*|string), city: (string|string), postalCode: (*|string), billingAddress: (string|string), state: (string|string)}}
 */
const getGooglePayBillingInfo = gPay => {
  return {
    billingAddress: gPay?.info?.billingAddress.address1 || '',
    postalCode: gPay?.info?.billingAddress.postalCode || '',
    city: gPay?.info?.billingAddress.locality || '',
    state: gPay?.info?.billingAddress.administrativeArea || '',
    country: gPay?.info?.billingAddress.countryCode || ''
  };
};

/**
 * Returns the payment info returned by Apple Pay checkout
 * @param applePayBillingContact
 * @returns {{country: (*|string), city: (string|string), postalCode: (*|string), billingAddress: (string|string), state: (string|string)}}
 */
const getApplePayBillingInfo = applePayBillingContact => {
  return {
    billingAddress: applePayBillingContact?.addressLines[0] || '',
    postalCode: applePayBillingContact?.postalCode || '',
    city: applePayBillingContact?.locality || '',
    state: applePayBillingContact?.administrativeArea || '',
    country: applePayBillingContact?.countryCode || ''
  };
};

/**
 * Returns a contact and billing info based on user logged in for formik.
 * @param {Object} profile Payment form data
 */
const setContactandBillingAddressInfo = (profile, selectedPaymentMethod) => {
  const { currentLodge } = store.getState();
  let contactAndBillingAddressInfo = { ...getInitialValues(selectedPaymentMethod) };
  if (profile) {
    contactAndBillingAddressInfo.contactInfo = {
      phone: maskPhone(profile.phoneNumber) || '',
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      email: profile.email || '',
      textAgreement: currentLodge.resortLocation !== SITE_NIAGON ? true : false
    };
    contactAndBillingAddressInfo.billingInfo = {
      billingAddress: profile.streetLine1 || '',
      addressLineTwo: profile.streetLine2 || '',
      postalCode: profile.zip || '',
      city: profile.city || '',
      state: profile.stateProvince || '',
      country: profile.country || SUPPORTED_COUNTRIES.UnitedStates
    };
  }
  return contactAndBillingAddressInfo;
};

/**
 * Returns a well formed payload to be used by createReservation.
 * @param {Object} formValues Payment form data
 * @param {Object} stateValues Redux state data
 */
const getPayload = async (formValues, stateValues, callBack = () => {}) => {
  const {
    paymentOptions,
    billingInfo,
    contactInfo,
    specialRequests,
    specialRequestsOther,
    specialOccasion
  } = formValues;
  const { profile, suiteRates } = stateValues;
  const payload = {
    profileId: profile.user ? profile.user.id : null,
    payment: getPaymentDetails(paymentOptions, stateValues),
    address: getAddressDetails(billingInfo, contactInfo),
    details: getOrderDetails(
      {
        specialRequests,
        specialRequestsOther,
        specialOccasion
      },
      stateValues
    ),
    customer: getCustomerDetails(contactInfo),
    expectedRates: suiteRates
  };
  try {
    return await createReservationSchema.validate(payload);
  } catch (error) {
    callBack(error?.errors[0] ?? 'Something Went Wrong');
    console.error(error);
    // null catch statement to prevent error from breaking the app
  }
};

/**
 * @function
 * Check if the all contact and billing information is complete before we
 * render the info text component
 * @param {Object} contactInfo Formik data with the contactInfo
 * @param {Object} billingInfo Formik data with the billingInfo
 * @Return {Boolean} If contactInfo and billingInfo haven't any null or empty properties then the result will be true
 */
const allFieldsProvided = contactInfo => {
  if (!contactInfo) return false;

  return checkObjectProperties(contactInfo);
};

/**
 * @function
 * Returns requited data to render payment tabs depending on calculated values
 * @param {Boolean} isPlanPage
 * @returns
 */
const getPaymentMethods = (isPlanPage, resortLocation) => {
  const { CREDIT_CARD, AFFIRM, PAYPAL, SHIFT4, APPLE_PAY, GOOGLE_PAY } = paymentMethodsTypes;

  const methods = {
    [CREDIT_CARD]: {
      isEnabled: true,
      isEnabledOutsidePlanPage: true,
      order: 1
    },
    [AFFIRM]: {
      isEnabled: isLocationAvailableWithAffirm(resortLocation),
      isEnabledOutsidePlanPage: false,
      order: 4
    },
    [PAYPAL]: {
      isEnabled: isPayPalEnabled(process.env.REACT_APP_PAYPAL_ENABLED_AT, resortLocation),
      isEnabledOutsidePlanPage: true,
      order: 5
    },
    [SHIFT4]: {
      isEnabled: process.env.REACT_APP_ENABLE_SHIFT4 === 'true',
      isEnabledOutsidePlanPage: true,
      order: 6
    },
    [APPLE_PAY]: {
      isEnabled: process.env.REACT_APP_ENABLE_APPLE_PAY,
      isEnabledOutsidePlanPage: true,
      order: 7
    },
    [GOOGLE_PAY]: {
      isEnabled: process.env.REACT_APP_ENABLE_GOOGLE_PAY,
      isEnabledOutsidePlanPage: true,
      order: 8
    }
  };

  const availablePaymentMethods = Object.values(paymentMethodsTypes).filter(method => {
    return isPlanPage
      ? methods[method]?.isEnabled
      : methods[method]?.isEnabled && methods[method]?.isEnabledOutsidePlanPage;
  });

  let availableSpace = 100;

  return availablePaymentMethods
    .map((method, idx) => {
      const { length } = availablePaymentMethods;
      if (availableSpace % length === 0 || idx !== 0) {
        return { name: method, width: `${availableSpace / length}%` };
      }

      const firstTabSize = Math.ceil(availableSpace / length / 10) * 10;
      availableSpace -= firstTabSize;
      return { name: method, width: `${firstTabSize}%` };
    })
    .sort((a, b) => a.order < b.order);
};

const fetchShift4WalletConfig = async (accessBlock, i4GoServerUrl) => {
  try {
    const payApiClient = new PaymentApiClient();
    const { data } = await payApiClient.getShift4WalletConfig(accessBlock, i4GoServerUrl);
    return data;
  } catch (e) {
    console.log(e);
  }
};

const approveShift4WalletTransaction = async (accessBlock, token, paymentType, i4GoServerUrl) => {
  try {
    const applePayToken = paymentType === APPLE_PAY_TYPE ? token : '';
    const googlePayToken = paymentType === GOOGLE_PAY_TYPE ? token : '';
    const payApiClient = new PaymentApiClient();
    const { data } = await payApiClient.approveShift4WalletTransaction(
      accessBlock,
      applePayToken,
      googlePayToken,
      i4GoServerUrl
    );
    return data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchTiogaTokenAccessBlock = async (resortLocation, paymentType, totalToCharge) => {
  try {
    const payApiClient = new RoomBookingApiClient();
    return await payApiClient.getShift4i4AccessBlockTioga(resortLocation, paymentType, totalToCharge);
  } catch (e) {
    console.error(e);
  }
};

/**
 * Create shift4i4Go vaulted card details
 *
 * @function
 * @param {number, ccMonth, ccYear, cidNumber, holder} creditCardData credt Card data to be tokenized
 * @param {postalCode, street1} billingAddressData billing address data to be tokenized
 */
const fillVaultedCardDetails = async (creditCardData, billingAddressData, resortLocation) => {
  const { number: ccNumber, ccMonth, ccYear, cidNumber: cvv2Code, holder: ccHolder } = creditCardData;
  const { postalCode, street1 } = billingAddressData;
  const cardTokenAccessBlockData = await fetchTiogaTokenAccessBlock(resortLocation, 1, 0);
  const ccStreetAddress = street1.replace(/(^\d+)(.+$)/i, '$1');
  const data = {
    i4go_accessblock: cardTokenAccessBlockData.data.token,
    i4go_cardnumber: ccNumber,
    i4go_expirationmonth: ccMonth,
    i4go_expirationyear: ccYear,
    i4go_cvv2code: cvv2Code,
    i4go_cardholdername: ccHolder,
    i4go_postalcode: postalCode,
    i4go_streetaddress: ccStreetAddress
  };
  const vaultedCardDetails = (
    await new Shift4i4GoApiClient().getVaultedCardDetails(cardTokenAccessBlockData.data.url, data)
  ).data;

  return {
    vaultedCardId: vaultedCardDetails.i4go_uniqueid,
    lastFourDigits: vaultedCardDetails.otn.cardnumber.replaceAll('X', '').trim(),
    cardnumber: vaultedCardDetails.otn.cardnumber,
    cidNumber: '',
    number: vaultedCardDetails.otn.cardnumber
  };
};

export const optionsVariationA = [
  paymentMethodsTypes.CREDIT_CARD,
  paymentMethodsTypes.APPLE_PAY,
  paymentMethodsTypes.GOOGLE_PAY,
  paymentMethodsTypes.AFFIRM,
  paymentMethodsTypes.PAYPAL
];

export const optionsVariationB = [
  paymentMethodsTypes.APPLE_PAY,
  paymentMethodsTypes.GOOGLE_PAY,
  paymentMethodsTypes.CREDIT_CARD,
  paymentMethodsTypes.AFFIRM,
  paymentMethodsTypes.PAYPAL
];

export const transformPaymentDataToOptions = (paymentMethodsComponents, optionsVariation) =>
  optionsVariation.reduce((paymentMethods, currentOption) => {
    if (!paymentMethodsComponents[currentOption].show || !paymentMethodsComponents[currentOption].allowedOnLocation)
      return paymentMethods;

    return [
      ...paymentMethods,
      {
        paymentMethod: {
          uuid: currentOption,
          ...paymentMethodsComponents[currentOption]
        }
      }
    ];
  }, []);

export const getSelectedPaymentMethod = (
  appleGooglePayMethodsFirst,
  isGooglePaymentActive,
  availablePaymentMethods
) => {
  if (!appleGooglePayMethodsFirst) return paymentMethodsTypes.CREDIT_CARD;
  if (window.ApplePaySession && availablePaymentMethods.includes(paymentMethodsTypes.APPLE_PAY))
    return paymentMethodsTypes.APPLE_PAY;
  if (isGooglePaymentActive && availablePaymentMethods.includes(paymentMethodsTypes.GOOGLE_PAY))
    return paymentMethodsTypes.GOOGLE_PAY;
  return paymentMethodsTypes.CREDIT_CARD;
};

export {
  allFieldsProvided,
  approveShift4WalletTransaction,
  fetchShift4WalletConfig,
  fillVaultedCardDetails,
  getAffirmCheckoutPayload,
  getApplePayBillingInfo,
  getGooglePayBillingInfo,
  getPayload,
  getPaymentMethods,
  getPaypalBillingInfo,
  getPaypalPaymentInfo,
  paymentFormLogos,
  paymentMethodsTypes,
  setContactandBillingAddressInfo
};
